<template>
<!--  待办事项-->
  <el-card shadow="never" class="todo-box">
    <div slot="header" class="portal-common-title">
      <span>{{title}}</span>
    </div>
    <div class="todo-box-body">
      <router-link class="item" to="/plugin/workflow/process/todo">
        <i class="icon-ym icon-ym-flowTodo"></i>
        <div class="text">
          <p class="num">{{numberOne}}</p>
          <p class="name">待办事宜</p>
        </div>
      </router-link>
      <router-link class="item" to="/plugin/workflow/process/my-done">
        <i class="icon-ym icon-ym-flowEntrust"></i>
        <div class="text">
          <p class="num">{{numberTwo}}</p>
          <p class="name">我的已办</p>
        </div>
      </router-link>
      <router-link class="item" to="/plugin/workflow/process/done">
        <i class="icon-ym icon-ym-flowDone"></i>
        <div class="text">
          <p class="num">{{numberThree}}</p>
          <p class="name">办结事宜</p>
        </div>
      </router-link>
      <router-link class="item" to="/plugin/workflow/process/copy">
        <i class="icon-ym icon-ym-flowDone"></i>
        <div class="text">
          <p class="num">{{numberFour}}</p>
          <p class="name">抄送事宜</p>
        </div>
      </router-link>
      <router-link class="item" to="/plugin/workflow/process/claim">
        <i class="icon-ym icon-ym-flowDone"></i>
        <div class="text">
          <p class="num">{{numberFive}}</p>
          <p class="name">待签事务</p>
        </div>
      </router-link>
    </div>
  </el-card>
</template>
<script>
// import { getFlowTodoCount } from '@/api/home';
import {todoList as getTodoList, myDoneList, doneList, copyList, claimList} from "@/api/plugin/workflow/process";
export default {
  props: {
    title: { type: String, default: '' }
  },
  data() {
    return {
      entrust: 0,
      flowDone: 0,
      toBeReviewed: 0,
      todoPage: {
        pageSize: 9999,
        currentPage: 1,
        total: 0,
      },
      numberOne:'',
      numberTwo:'',
      numberThree:'',
      numberFour:'',
      numberFive:''
    }
  },
  created() {
    //我的待办接口需要新增
    this.getData()
  },
  methods: {
    getData() {
      getTodoList(this.todoPage.currentPage, this.todoPage.pageSize).then(res => {
        this.numberOne = res.data.data.total;
      });
      //已办
      myDoneList(this.todoPage.currentPage, this.todoPage.pageSize).then(res => {
        this.numberTwo = res.data.data.total;
      });
      //办结事宜
      doneList(this.todoPage.currentPage, this.todoPage.pageSize).then(res => {
        this.numberThree = res.data.data.total;
      });
      //抄送事宜
      copyList(this.todoPage.currentPage, this.todoPage.pageSize).then(res => {
        this.numberFour = res.data.data.total;
      });
      //待签事务
      claimList(this.todoPage.currentPage, this.todoPage.pageSize).then(res => {
        this.numberFive = res.data.data.total;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.todo-box {
  ::v-deep .el-card__body {
    width: 100%;
    height: calc(100% - 55px);
  }
  .todo-box-body {
    padding: 0 30px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      height: 56px;
      display: block;
      i {
        width: 56px;
        height: 56px;
        margin-right: 14px;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        line-height: 56px;
        font-size: 30px !important;
        &.icon-ym-flowTodo {
          background: #f68900;
        }
        &.icon-ym-flowEntrust {
          background: #1890ff;
        }
        &.icon-ym-flowDone {
          background: #7b1ae1;
        }
      }
      .text {
        display: inline-block;
        height: 56px;
        .num {
          font-size: 20px;
          line-height: 36px;
          margin: 0;
        }
        .name {
          font-size: 14px;
          color: #666;
          margin: 0;
        }
      }
    }
  }
}
</style>
