<template>
  <el-card shadow="never" class="portal-todoList-box">
    <div slot="header" class="portal-common-title">
      <span>{{title}}</span>
    </div>
    <div class="portal-todoList-box-body">
      <template v-if="list.length">
        <a class="item com-hover" @click="goDetail(item)" v-for="(item, i) in list" :key="i">
          <span class="name">{{item.processDefinitionName}}</span>
          <span class="time">{{item.createTime | toDate('yyyy-MM-dd')}}</span>
        </a>
      </template>
      <div class="portal-common-noData" v-else>
        <img src="@/assets/images/portal-nodata.png" alt="" class="noData-img">
        <p class="noData-txt">暂无数据</p>
      </div>
    </div>
  </el-card>
</template>
<script>
import { todoList as getList } from "@/api/plugin/workflow/process";
export default {
  props: {
    title: { type: String, default: '' }
  },
  data() {
    return {
      list: [],
      page: {
        pageSize: 999,
        currentPage: 1,
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      getList(this.page.currentPage, this.page.pageSize).then(res => {
        this.list = res.data.data.records;
      });
    },
    goDetail(item) {
      const { id, taskId, processInstanceId, processId, formKey, formUrl, processDefKey } = item
      let param = Buffer.from(JSON.stringify({
        processId: id,
        taskId,
        processInsId: processInstanceId || processId,
        processDefKey
      })).toString('base64')
      this.$router.push('/workflow/process/detail/'+param)
    }
  }
}
</script>
