<template>
  <el-card shadow="never" class="portal-todoList-box">
    <div slot="header" class="portal-common-title">
      <span>{{title}}</span>
    </div>
    <div class="portal-todoList-box-body">
      <template v-if="list.length">
        <a class="item com-hover" @click="goDetail(item)" v-for="(item, i) in todoList" :key="i">
          <span class="name">{{item.processDefinitionName}}</span>
          <span class="time">{{item.createTime | toDate('yyyy-MM-dd')}}</span>
        </a>
      </template>
      <div class="portal-common-noData" v-else>
        <img src="@/assets/images/portal-nodata.png" alt="" class="noData-img">
        <p class="noData-txt">暂无数据</p>
      </div>
    </div>
  </el-card>
</template>
<script>
import {sinomatodo} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
export default {
  props: {
    title: { type: String, default: '' }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      todoList: [],
      page: {
        pageSize: 999,
        currentPage: 1,
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      sinomatodo(1, 10, { ownerName: this.userInfo.account }).then((res) => {
        if (res.data.code == 200) {
          this.todoList = res.data.data.records;
        }
      });
    },
    goDetail(row) {
      if(!row.url)return this.$message.error('跳转地址错误，请检查下地址是否已经配置')
      let path = row.url.split("?")[0];
      let pathArr=["/#/business/fillReport/index","/#/process/external/iframe/detail"]
      if (pathArr.includes(path)) {
        window.open(row.url, "_blank");
      } else {
        this.$router.push(row.url);
      }
    }
  }
}
</script>
